import { Link } from 'gatsby'
import React from 'react'
import FacebookIcon from '../images/icons/logo-facebook.svg'
import LinkedinIcon from '../images/icons/logo-linkedin.svg'
import YoutubeIcon from '../images/icons/logo-youtube.svg'
class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() { }

  render() {
    return (
      <footer className="footer biq-text-white" style={{ flexShrink: 0, borderTop: '2px solid white' }}>
        <div className="flex flex-col w-full px-2 py-4 xl:px-4 justify-center h-full my-auto">
          <div className="flex flex-row w-full flex-wrap" style={{maxWidth: '1280px', margin: 'auto'}}>
            <div className="flex flex-col w-full lg:w-1/2 place-items-start biq-text-white">
              <div className="biq-text-xl xl:biq-text-6xl font-semibold">
                THE BREATHING IQ
            </div>
              <div className="biq-text-md xl:biq-text-xl">Helping the world catch their breath</div>
            </div>
            <div className="flex flex-col mt-8 lg:mt-0 w-full lg:w-1/2 place-items-flex-end">
              <div className="flex flex-row justify-center place-items-center" >
                <a
                  href="https://www.facebook.com/thebreathingiq"
                  target="_blank"
                  className="flex p-4"
                >
                  <FacebookIcon fill="#faf7f2" height="30px" width="30px" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCjzYQ_c-QlN6gwGQWYnVERQ"
                  target="_blank"
                  className="flex p-4"
                >
                  <YoutubeIcon fill="#faf7f2" height="30px" width="30px" />
                </a>
                <a
                  href="https://www.linkedin.com/company/the-breathingiq/"
                  target="_blank"
                  className="flex p-4"
                >
                  <LinkedinIcon fill="#faf7f2" height="30px" width="30px" />
                </a>
              </div>
              <a className="flex justify-center lg:p-4 biq-text-white" href="mailto:hello@thebreathingiq.com" >
                hello@thebreathingiq.com
              </a>
            </div>
          </div>
          <div className="flex biq-text-xs justify-center py-4 mt-8 lg:mt-0 w-full biq-text-white" >
          © THE BREATHING IQ. 2020 All rights reserved.
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
