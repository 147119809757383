import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { navigate } from '@reach/router'
import MenuIcon from '../images/icons/menu.svg'
import { logout, isLoggedIn } from '../utils/auth'
import { Auth } from 'aws-amplify'
import BreathingIQLogo from '../images/icons/logo-thebreathingiq.svg'
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    }
  }

  componentDidMount() {

    window.addEventListener('scroll', e => {
      if (document.querySelectorAll('.header').length > 1) {
        const nav = document.querySelectorAll('.header')
        var sticky = nav.offsetTop
        for (let i = 0; i < nav.length; i++) {
          if (window.pageYOffset > sticky) {
            nav[i].classList.add('sticky-header')
          } else {
            nav[i].classList.remove('sticky-header')
          }
        }
      } else {
        const nav = document.querySelector('.header')
        if (nav != null) {
          var sticky = nav.offsetTop
          if (window.pageYOffset > sticky) {
            nav.classList.add('sticky-header')
          } else {
            nav.classList.remove('sticky-header')
          }
        }
      }
    })
    // const parallax =
    // document.querySelector('.parallax').addEventListener('scroll', function() {
    //   console.log(this.scrollTop);
    // });
  }

  toggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
    if (!this.state.isMenuOpen === true) {
      window.addEventListener('scroll', e => {
        console.log(document.querySelectorAll('.menu').length);
        if (document.querySelectorAll('.menu').length > 1) {
          const menu = document.querySelectorAll('.menu')
          var sticky = menu.offsetTop
          for (let i = 0; i < menu.length; i++) {
            if (window.pageYOffset > sticky) {
              menu[i].classList.add('sticky-menu')
            } else {
              menu[i].classList.remove('sticky-menu')
            }
          }
        }
      })
    }
  }

  render() {
    const headerTextClass =
      'text-xl xl:text-4xl' + (this.location.pathName === '/')
        ? 'biq-text-white'
        : 'biq-text-black'
    return (
      <>
        <header
          className={`header`}
          style={{ marginTop: this.props.isNotificationOpen ? '40px' : '0' }}
        >
          <div className="content p-4">
            <div className="logo">
              <Link to="/" className="header-text flex flex-row place-items-center ">
                <BreathingIQLogo fill="#3c5164" stroke="white" height="44px" width="44px" />
                THE BREATHING IQ
              </Link>
            </div>
            {/* <div onClick={() => this.toggleMenu()} className="flex xl:hidden justify-center place-items-center">
              <MenuIcon fill="blue" stroke="white" height="40px" width="40px" />
            </div> */}
            <div className="hidden xl:flex flex-row justify-center place-items-center">
              {/* <Link to="/biq" className="flex mx-2">
              <button className="btn-header">The BIQ</button>
            </Link> */}
              {/* <Link to="/certification" className="flex mx-2">
                <button className="btn-light-outline header-text header-cta rounded-full border-solid border-2 w-56">Get Certified</button>
              </Link> */}
              {/* <Link to="/app/login" className="flex mx-2">
              <button className="btn-header">Programs</button>
            </Link>
            <Link to="/app/login" className="flex mx-2">
              <button className="btn-header">Kids</button>
            </Link>
            <Link to="/app/login" className="flex mx-2">
              <button className="btn-header">Learn</button>
            </Link> */}
              {/* <Link to="/app/login" className="flex mx-2">
              <button className="btn-header">Log In</button>
            </Link>
            <Link to="/app/signup" className="flex mx-2">
              <button className="btn-header">Sign Up</button>
            </Link>
            {isLoggedIn() && (
              <>
                <p
                  onClick={() =>
                    Auth.signOut()
                      .then(logout(() => navigate('/app/login')))
                      .catch(err => console.log('eror:', err))
                  }
                  style={styles.link}
                >
                  Sign Out
                </p>
                <Link to="/app/signup" className="flex mx-2">
                  <button className="btn-header">Your Profile</button>
                </Link>
              </>
            )} */}
            </div>
          </div>
          {this.state.isMenuOpen ? (
            <div className="flex xl:hidden menu p-6 transition-all duration-300 select-none">
              <div className="block">
                {/* <Link to="/certification" className="flex mx-2">
                  <button className="btn-light-outline menu-button header-text header-cta rounded-full border-solid border-2 w-56">Get Certified</button>
                </Link> */}
              </div>
            </div>) : ''}
        </header>
      </>
    )
  }
}

const styles = {
  headerTitle: {
    color: 'white',
    textDecoration: 'none',
  },
  link: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  },
}

export default Header
