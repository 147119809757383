import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Location } from '@reach/router'
import Header from './header'
import Footer from './footer'
import SEO from './SEO'
import '../scss/index.scss';
// import './layout.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isNotificationOpen: false,
    }
  }

  componentDidMount() { }

  toggleNotification() {
    const notificationElement = document.getElementById('notification')
    notificationElement.style.display = 'none'
    this.setState({ isNotificationOpen: !this.state.isNotificationOpen })
  }

  render() {
    return (
      <Location>
        {locationProps => (
          <StaticQuery
            query={graphql`
              query SiteTitleQuery {
                site {
                  siteMetadata {
                    title
                  }
                }
              }
            `}
            render={data => (
              <>
                <SEO />
                <Helmet
                  meta={[
                    { name: 'keywords', content: 'breathing, covid, breathing intelligence, breath, lungs' },
                  ]}
                >
                  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
                  />
                  <html lang="en" />
                  <link
                    rel="stylesheet"
                    href="/animation/animate.css"
                    async
                  ></link>
                </Helmet>
                {/* <div id="notification">
                  <Link to="/covid">Covid Update and Breathing Exercises</Link>
                  <a className="ml-5" id="close" onClick={() => this.toggleNotification()}>
                  </a>
                </div> */}
                <Header
                  siteTitle={data.site.siteMetadata.title}
                  {...this.state}
                />
                <div
                  style={{
                    paddingTop: 0,
                    zIndex: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    marginTop: this.state.isNotificationOpen ? '40px' : '0',
                  }}
                >
                  {this.props.children}
                </div>
                <Footer />
              </>
            )}
          />
        )}
      </Location>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
